import { DEFAULT_TRANSLATION } from "../constants/defaultTranslation";
import { flattenMessages } from "../utils";
import { useEffect } from "react";

export const useTranslation = async (setTranslation, appLocale) => {
  let langLocale = appLocale || DEFAULT_TRANSLATION;

  const setDefaultTranslation = (translation) => {
    const flattedMessages = translation;
    setTranslation(flattedMessages);
    window.phraseTranslation = flattedMessages;
  };

  const initTranslationLanguage = async () => {
    try {
      const defaultTranslation = await import(`../translations/${langLocale}.json`);
      setDefaultTranslation(defaultTranslation)
    } catch (error) {
      const englishTranslation = await import(`../translations/${DEFAULT_TRANSLATION}.json`);
      setDefaultTranslation(englishTranslation);
    }
  };

  useEffect(() => {
    initTranslationLanguage();
  }, [langLocale]);
};