import PropTypes from "prop-types";
import OpenIcon from './assets/open';
import CloseIcon from "./assets/close";
import { Tag } from '../../components';
import ProIcon from '../../assets/svg/pro';
import HelperCard from "../HelperCard";
import { FormattedMessage } from 'react-intl';

const Accordion = (props) => {
  const { label, isOpen, onClick, children, isPremium, dataAttribute, helper, reactIntlId, reactIntValues,  builderStep, stepSection, accClassName } = props
  return (
    <>
      <div 
        className={`bg-gray-400 py-[11px] px-[16px] flex items-center cursor-pointer ${!isOpen && 'mb-[4px]'} rounded-[4px] ${accClassName || 'justify-between'}`} 
        onClick={onClick}
        {...(dataAttribute && { 'data-attribute': `info-accordian-${dataAttribute}`})}
      >
        <div className="flex">
          <div className="text-secondary text-lg">
            { reactIntlId ? <FormattedMessage values={reactIntValues} id={builderStep ? `${builderStep}.${stepSection}.${reactIntlId}` : reactIntlId} defaultMessage={label} /> : label }
          </div>
          {helper?.title && helper?.description && window.isMailmunch && <HelperCard {...helper} />}
          { isPremium && <Tag className="ml-[8px]" action={'danger'} icon={<ProIcon className="mr-[10px]" />} label={'Pro'} /> }
        </div>
        { 
          isOpen ?
          <CloseIcon /> :
          <OpenIcon  /> 
        }
      </div>
      {
        isOpen &&
        <div className="px-[16px] pt-[24px] pb-[16px] bg-gray-100 mb-[16px]">
          { children }
        </div>
      }
    </>
  )
}

Accordion.propTypes = {
  label: PropTypes.string || PropTypes.object,
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  helper: PropTypes.object,
  accClassName: PropTypes.string,
};

Accordion.defaultProps = {
  label: 'click to open',
  isOpen: false,
  onClick: null,
  accClassName: '',
};

export { Accordion }