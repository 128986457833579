import React, { useEffect, useState } from "react";
import { render } from "react-dom";
import { ApolloProvider } from "@apollo/client";
import client from "./graphql/client";
import Routes from "./routes";
import "./tailwind.css";
import { getStorageItem, fetchFromStorage, removeFromStorage } from "./utils";


const App = () => {
  const token = fetchFromStorage('userToken')
  const tokenExp = fetchFromStorage('userTokenExpiry')
  const isUserActive = getStorageItem('isUserActive')

  const resetUserActivity = () => {
    localStorage.setItem('isUserActive', 'true')
    setTimeout(() => localStorage.setItem('isUserActive', 'false'), 15 * 60 * 1000)
  };

  const checkTokenExpiry = () => {
    if (token && tokenExp && new Date(tokenExp) < new Date()) {
      removeFromStorage('userToken')
      removeFromStorage('userTokenExpiry')
    }
  };

  useEffect(() => {
    // check if token and token expiry is set and token expiry is in 5 minutes
    const fiveMinutes = 5 * 60 * 1000
    const isTokenExpiring = token && tokenExp && new Date(tokenExp) - new Date() < fiveMinutes
    
    if (isTokenExpiring) {
      // Listen for mouse and keyboard events
      window.addEventListener('mousemove', resetUserActivity);
      window.addEventListener('keydown', resetUserActivity);
      window.addEventListener('beforeunload', checkTokenExpiry);
    }

    return () => {
      // Clean up event listeners
      window.removeEventListener('mousemove', resetUserActivity);
      window.removeEventListener('keydown', resetUserActivity);
      window.removeEventListener('beforeunload', checkTokenExpiry);
    };
  }, []);

  useEffect(() => {
    // check if user token is expired.
    if (isUserActive) {
      checkTokenExpiry();
    }
  }, [isUserActive])

  return (
    <Routes />
  );
};

render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

