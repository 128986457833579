import { ApolloClient, createHttpLink, defaultDataIdFromObject, InMemoryCache, makeVar } from "@apollo/client";
import { ApolloLink } from "apollo-link";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import GetConfig from "./query/config";
import { ToastNotification } from '../components'
import { getStorageItem, removeStorageItem, fetchFromStorage, removeFromStorage } from "../utils";

const handleAuthError = () => {
  ToastNotification({
    id: 'unauthorized',
    icon: "alert",
    autoClose: false,
    color: "danger",
    body: <span>Your session has been expired please <a className="text-primary underline" onClick={() => removeFromStorage('userToken')} href={`//${window.appDomain}/signout`}>click here</a> to login again </span>,
  })
}

export const themePrimaryColor = makeVar('black')
const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_API_URL, // mailmunchPlatform.get_graphql_api_url(),
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // eslint-disable-next-line no-undef
  const token = fetchFromStorage('userToken') //|| currentUser.getGraphQLAccessToken();
  window.token = token
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      mode: 'no-cors',
    },
  };
});

const cache = new InMemoryCache({
  // typePolicies:{
  //   Query: {
  //     fields: {
  //       businessProfile: {
  //         read(_,{ args, toReference }){
  //           return toReference({ __typename: 'BusinessProfile', id: args.id })
  //         }
  //       }
  //     }
  //   }
  // },
  dataIdFromObject: (object) => {
    const typeMap = {
      widget: "Widget",
      landing_page: "LandingPage",
      shopify_page: "ShopifyPage",
      shopify_product_page: "ShopifyProductPage",
      shopify_collection_page: "ShopifyCollectionPage",
      shopify_home_page: "ShopifyHomePage",
      shopify_coming_soon_page: "ShopifyComingSoonPage",
      welcome_email: "Email",
      broadcast_email: "Email",
      //automation_email: "JourneyEmail",
      automation: "Journey",
      drip_campaign: "DripCampaign",
      drip_email: "DripEmail",
      subscribers: "Subscribers",
    };
    switch (object.__typename) {
      case "Campaign":
        return `${typeMap[object.type]}-${object.id}`;
      case "Subscribers":
        return `${typeMap[object.type]}-${object.id}`;
      default:
        return defaultDataIdFromObject(object); // fall back to default handling
    }
  },
});

cache.writeQuery({
  query: GetConfig,
  data: {
    siteId: null,
    visible: false,
    stepName: null,
    isMailchimp: false,
    channelName: null,
  },
});

export const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError, operation, forward }) => {
      if(networkError?.result?.errors[0]?.extensions?.code == 'UNAUTHENTICATED') return handleAuthError()
      if (graphQLErrors){
        graphQLErrors.forEach(({ extensions, message, locations, path }) => {
            if(process.env.NODE_ENV === 'development') {
              console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
            }
            if (extensions.code == "UNAUTHENTICATED") {
              return handleAuthError()
            }
          }
        );
      }
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    authLink.concat(httpLink),
  ]),
  cache,
  resolvers: {
    Mutation: {
      changeStep: (_root, variables, { cache, getCacheKey }) => {
        cache.writeQuery({
          query: GetConfig,
          data: {
            stepName: variables.stepName,
          },
        });
        return null;
      },
      changeVisible: (_root, variables, { cache, getCacheKey }) => {
        cache.writeQuery({
          query: GetConfig,
          data: {
            visible: variables.visible,
          },
        });
        return null;
      },
    },
  },
});

export default client;
