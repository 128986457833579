import { useRef, useState, useEffect } from "react"
import PropTypes from 'prop-types'
import { IconSearch, IconChevronDown } from "@tabler/icons"
import { Input } from "../Input"
import { Checkbox } from "../Checkbox"
import IconSort  from "../../assets/svg/IconSort"

export const Table = ({ 
  headings, 
  rowData, 
  searchEnabled, 
  searchCallback, 
  searchValue, 
  searchPlaceholder, 
  hasTopPadding, 
  hasBottomUnerline,
  rowsMaxHeight,
  scrollable,
  titleData,
  sortable=false,
  overflowHidden=true
}) => {
  const [sortedRowData, setSortedRowData] = useState(rowData)
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' })
  const [rowsHeight, setRowsHeight] = useState(null)
  const tableHeadingRef = useRef(null)
  const headingsParentRef = useRef(null)

  useEffect(() => {
    if(rowsMaxHeight){
      setRowsHeight(`${rowsMaxHeight - tableHeadingRef.current?.clientHeight}px`)
    }
  }, [])

  useEffect(() => {
    setSortedRowData(rowData)
  }, [rowData])

  const sortData = (key) => {
    let direction = 'ascending'
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending'
    }
    setSortConfig({ key, direction })

    const sortedData = [...sortedRowData].sort((a, b) => {
      const aValue = typeof a.columns[key] === 'object' ? a.columns[key]?.props?.value : a.columns[key]
      const bValue = typeof b.columns[key] === 'object' ? b.columns[key]?.props?.value : b.columns[key]
   
      
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return direction === 'ascending' 
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue)
      }
      
      if (aValue < bValue) {
        return direction === 'ascending' ? -1 : 1
      }
      if (aValue > bValue) {
        return direction === 'ascending' ? 1 : -1
      }
      return 0
    })

    setSortedRowData(sortedData)
  }

  const Row = ({ item }) => {
    const { columns = {} } = item || {}

    return(
      <>
        {
          Object.keys(headings).map((heading, headingIndex) => {
            return(
              <Cell key={headingIndex} heading={heading} headingIndex={headingIndex} columns={columns}/>
            )
          })
        }
      </>
    )
  }

  const Cell = ({ heading, columns }) => {
    return(
      <div
        style={{width: `${headings[heading].width}`}}
        className={`${headings[heading].actions ? 'ml-auto' : ''} min-w-[0px] py-[15px] break-words text-sm text-left text-secondary pl-[20px] ${headings[heading].type === "checkbox" ? 'px-[0px] py-[0px]' : ''}`}>
        <div 
        className={`block overflow-ellipsis whitespace-nowrap ${overflowHidden && 'overflow-hidden'}`}>
          {columns[heading]}
          </div>
      </div>
    )
  }

  const SortableHeading = ({ heading, headingKey }) => {
    const isSorted = sortConfig.key === headingKey
    const direction = sortConfig.direction
    
    return (
      <div
        className={`flex items-center ${sortable && 'cursor-pointer'}`}
        onClick={() => sortable && sortData(headingKey)}
      >
        {heading.title}
        {
          sortable && <span className="ml-2">
             <IconSort size={16}   
              upColor={direction == 'ascending' && isSorted ? '#2E384D' : '#B3B4B6'}
              downColor={direction == 'descending' && isSorted ? '#2E384D' : '#B3B4B6'}
             />
          </span>
        }
      </div>
    )
  }

  return (
    <div className={`min-h-full relative ${searchEnabled ? 'rounded-[8px]' : 'rounded-b-[8px]'} shadow bg-white ${overflowHidden && 'overflow-hidden'} border-b border-gray-200 w-full`}>
      <div className={`min-w-full divide-y divide-gray-200 rounded-[8px] ${hasBottomUnerline ? 'border-b border-gray-200' : ''}`}>
        <div ref={tableHeadingRef}>
          {
            searchEnabled &&
            <span className={"absolute top-[12px] right-[8px] z-10"}>
              <Input
                fontSize='12px'
                placeholder={searchPlaceholder}
                value={searchValue}
                icon={<IconSearch stroke={1.25} color={"#021747"} size={16} />}
                onChange={searchCallback}
              />
            </span>
          }
            {
              searchEnabled || hasTopPadding ? 
              <div className={`${searchEnabled ? 'pb-[56px]' : 'pb-[40px]'} w-full justify-center flex items-end relative z-0`}>
                {
                  titleData.length === 2 &&
                  <div className="w-full flex justify-between px-[16px] absolute top-[50%] translate-y-[-50%] left-[0px] leading-[17.78px] text-[15px]">
                    <div className="text-secondary">{titleData[0]}</div>
                    <div className="text-gray-800 opacity-70">{titleData[1]}</div>
                  </div>
                }
              </div> : null
            }
            <div ref={headingsParentRef} className={`border-b border-t border-gray-100 flex`}>
              {
                Object.entries(headings).map(([key, heading], headingIndex) => {
                  return(
                    <div
                      key={headingIndex}
                      style={{width: `${heading.width}`}}
                      className={`text-left pl-[20px] pr-[15px] ${heading.actions ? 'ml-auto' : ''} ${heading.srOnly ? `invisible` : ' pt-[8px] pb-[8px] text-left text-sm text-secondary'}`}
                    >
                      {!heading.actions && !heading.srOnly ? (
                        <SortableHeading heading={heading} headingKey={key} />
                      ) : (
                        heading.title
                      )}
                    </div>
                  )
                })
              }
            </div>
          </div>
         
          <div className={`${scrollable ? 'overflow-y-scroll' : ''} ${rowsHeight ? rowsHeight : ''}`}
            style={{...(rowsHeight && {maxHeight: rowsHeight})}}
          >
            {
              sortedRowData && sortedRowData.length ?
              sortedRowData.map((rowItem, rowIndex) => {
                return (
                  <div 
                    key={rowIndex}
                    className={`hover:bg-primary-lighter flex items-center border-b border-gray-100 whitespace-pre-line w-full break-words
                        ${rowItem.selected ? 'bg-lemon' : ''}
                        ${rowItem.disabled ? 'pointer-events-none opacity-50' : ''}
                      `}
                      style={{...(rowIndex === sortedRowData.length  && {borderBottomWidth: "1px", borderTopWidth: "0px"})}}
                    >
                    {
                      <Row item={rowItem}/>
                    }
                  </div>
                )
              })
              :
              <NoResultRow />
            }
          </div>
        </div>
    </div>
  )
}

const NoResultRow = () => {
  return (
    <div className='items-center flex justify-center p-[13.5px] text-gray-800'>No results</div>
  );
};

Table.propTypes = {
  searchPlaceholder: PropTypes.string,
  searchValue: PropTypes.string,
  rowData: PropTypes.array,
  headings: PropTypes.object,
  searchEnabled: PropTypes.bool,
  searchCallback: PropTypes.func,
  hasTopPadding: PropTypes.bool,
  hasBottomUnerline: PropTypes.bool,
  rowsMaxHeight: PropTypes.string,
  scrollable: PropTypes.bool,
  titleData: PropTypes.array
};

Table.defaultProps = {
  titleData: [],
  rowsMaxHeight: "",
  scrollable: false,
  searchValue: '',
  searchEnabled: false,
  searchCallback: () => console.log("Searching Table"),
  hasTopPadding: true,
  hasBottomUnerline: true,
  rowData: [
    {
      selected: true,
      columns: {
        checkbox: <Checkbox checked={true} callback={() => console.log("")}/>,
        name: <div>Jane Cooper</div>, 
        title: <div>Regional Paradigm Technician</div>, 
        role: <div>Admin</div>, 
        email: 'jane.cooper@example.com', 
        edit: 'Edit'
      }
    },
    {
      selected: false,
      columns: {
        checkbox: <Checkbox checked={false} callback={() => console.log("")}/>,
        name: 'Cody Fisher', 
        title: 'Product Directives Officer', 
        role: 'Owner', 
        email: 'cody.fisher@example.com', 
        edit: "Edit"
      }
    },
    {
      selected: true,
      disabled: true,
      columns: {
        checkbox: <Checkbox checked={true} callback={() => console.log("")}/>,
        name: 'Cody Fisher', 
        title: 'Product Directives Officer', 
        role: 'Owner', 
        email: 'cody.fisher@example.com', 
        edit: "Edit"
      }
    }
  ],
  headings: {
    checkbox: {
      title: <Checkbox checked={true} callback={() => console.log("")}/>,
      srOnly: false,
      type: 'checkbox',
      width: "10%"
    },
    name: {
      title: "Name",
      width: "20%"
    },
    title: {
      title: "Title",
      width: "30%"
    },
    email: {
      title: "Email",
      width: "20%"
    },
    role: {
      title: "Role",
      width: "10%"
    },
    edit: {
      title: "Edit",
      srOnly: true,
      width: "50px",
      actions: true
    }
  }
};