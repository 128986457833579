import { useHistory } from "react-router-dom";

import { ReactComponent as RouteNotFoundSVG } from './assets/routeNotFound.svg';
import { getStorageItem, fetchFromStorage } from "../../utils";
import Header from '../../components/Header'

const RouteNotFound = () => {
  const history = useHistory();
  const isLoggedIn = !(fetchFromStorage('userToken') == null)
  const isActivePartnerUser = isLoggedIn && window.isPartnerActive
  const path = (window.isAppflows && window.siteId) ? `/sites/${window.siteId}` : '/sites'
  return(
    <>
      {isActivePartnerUser && <Header />}
      <div className="h-screen w-full flex items-center justify-center flex-col">
      <RouteNotFoundSVG className="text-primary" fill="currentColor" />
      <div className='text-center text-2lg text-gray-900 font-extralight'>
        <div className='mt-[40px] mb-[15px] text-3xl font-normal'>Page not found!</div>
        <div className="">We don't know how you got here but you shouldn't be here.</div>
        <div className="">Try going back.</div>
        {
          isActivePartnerUser &&
          <div 
            onClick={() => history.push(path)}
            className="text-primary w-max m-auto font-normal mt-[10px] hover:cursor-pointer">
            Back to Home
          </div>
        }
      </div>
    </div>
    </>
  )
}

export default RouteNotFound