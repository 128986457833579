import PropTypes from 'prop-types';
import { formatDataAttribute } from '../../utils';

const RadioButton = ({ label, onClick, checked, dataAttribute }) => {
  const dataAttributeVal = `radio-${dataAttribute || formatDataAttribute(label)}`;
  return (
    <div onClick={onClick} className='flex w-max gap-x-[11px] hover: cursor-default'>
      <input
        checked={checked}
        readOnly={true}
        className='h-4 w-4 border-gray-300 mt-[2px]' 
        type="radio" 
        {...dataAttributeVal && { 'data-attribute': dataAttributeVal }}
      />
      <div className='sm:w-auto sm:max-w-max max-w-[270px]'>{label}</div>
    </div>
  );
}

RadioButton.propTypes = {
  label: PropTypes.node,
  checked: PropTypes.bool,
  onClick: PropTypes.func,
  dataAttribute: PropTypes.string
};

export { RadioButton };